<template>

  <!-- Product -->
  <div id="story-mode-product">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('pages.businessGuide.product.title')"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'productCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-2"
            type="productExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            help-me-write
            type="product"
            section-name="Product"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Business plan additional info -->
        <business-plan-additional-info
          section-name="Product"
          completed-step-name="productCompleted"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Unique Selling Proposition -->
        <!--        <div class="proposition">-->

        <!--          &lt;!&ndash; Title &ndash;&gt;-->
        <!--          <h2>{{ $t('pages.businessGuide.product.uniqueSellingProposition') }}</h2>-->
        <!--          &lt;!&ndash; /Title &ndash;&gt;-->

        <!--          &lt;!&ndash; Description &ndash;&gt;-->
        <!--          <p class="text-lead">-->
        <!--            {{ $t('pages.businessGuide.product.defineYourUniqueSellingProposition') }}-->
        <!--          </p>-->
        <!--          &lt;!&ndash; /Description &ndash;&gt;-->

        <!--          &lt;!&ndash; Grid Wrapper &ndash;&gt;-->
        <!--          <div class="grid-wrapper">-->
        <!--            <el-row :gutter="20">-->

        <!--              &lt;!&ndash; Add Unique Selling Proposition &ndash;&gt;-->
        <!--              <el-col :md="5" class="position-relative mb-3">-->
        <!--                <div class="add-new" @click="checkAddProposition">-->
        <!--                  <p>{{ $t('pages.businessGuide.product.addUniqueSellingProposition') }}</p>-->
        <!--                </div>-->
        <!--              </el-col>-->
        <!--              &lt;!&ndash; /Add Unique Selling Proposition &ndash;&gt;-->

        <!--              &lt;!&ndash; Unique Selling Proposition Placeholders &ndash;&gt;-->
        <!--              <div v-if="!propositionItems.length && $breakpoint.mdAndUp" @click="checkCardProposition">-->
        <!--                <UniqueSellingProposition-->
        <!--                  v-for="index in 3"-->
        <!--                  :key="index"-->
        <!--                  :proposition="null"-->
        <!--                  class="mb-3"-->
        <!--                />-->
        <!--              </div>-->
        <!--              &lt;!&ndash; /Unique Selling Proposition Placeholders &ndash;&gt;-->

        <!--              &lt;!&ndash; Unique Selling Propositions &ndash;&gt;-->
        <!--              <div @click="checkCardProposition">-->
        <!--                <UniqueSellingProposition-->
        <!--                  v-for="(proposition, index) in propositionItems"-->
        <!--                  :key="index"-->
        <!--                  :proposition="proposition"-->
        <!--                  class="mb-3"-->
        <!--                  @click="checkAddProposition"-->
        <!--                  @delete="deleteProposition(index)"-->
        <!--                />-->
        <!--              </div>-->
        <!--              &lt;!&ndash; /Unique Selling Propositions &ndash;&gt;-->

        <!--              &lt;!&ndash; Dialog Add Proposition &ndash;&gt;-->
        <!--              <dialog-add-proposition-->
        <!--                :dialog-add-proposition-visible="dialogAddPropositionVisible"-->
        <!--                @discard="dialogAddPropositionVisible = false"-->
        <!--              />-->
        <!--              &lt;!&ndash; /Dialog Add Proposition &ndash;&gt;-->

        <!--            </el-row>-->
        <!--          </div>-->
        <!--          &lt;!&ndash; /Grid Wrapper &ndash;&gt;-->

        <!--        </div>-->
        <!-- /Unique Selling Proposition -->

        <!-- Portfolio -->
        <div class="portfolio">
          <v-app><!-- Header -->
            <business-guide-header>
              <template #left>
                <!-- Title -->
                <h2>{{ $t('pages.businessGuide.product.productServicePortfolio') }}</h2>
                <p class="text-lead">
                  {{ $t('pages.businessGuide.product.explainProductsAndService') }}
                </p>
                <!-- /Title -->
              </template>
              <template #right>
                <erase-icon-button
                  v-if="removeButtonVisible"
                  @click="onEraseFromTemplate"
                />
                <business-guide-search
                  v-if="!isProductsEmpty"
                  v-model="searchValue"
                  class="mx-1"
                />
                <!-- View Switcher -->
                <view-switcher
                  v-if="isProductsEmpty === false"
                  :active-view="productView"
                  @activate-view="onActivateView"
                />
                <!-- /View Switcher -->
              </template>
            </business-guide-header>
            <!-- /Header --></v-app>

          <!-- Grid Wrapper -->
          <div class="grid-wrapper" :class="{ 'active': productView === 'grid' }">
            <el-row :gutter="20">

              <!-- Completed overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkProduct"
              />
              <!-- /Completed overlay -->

              <div
                v-if="isProductsEmpty"
                class="d-flex flex-wrap"
              >
                <el-col :md="8" class="d-flex width-xl-20 mb-3">
                  <add-new-card
                    class="width-xl-20"
                    :title="$t('pages.businessGuide.product.addProductOrService')"
                    :disabled="hasNotAccess"
                    @click="checkProduct"
                  />
                </el-col>

                <!-- Placeholder 1 -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" class="width-xl-20 mb-3">
                  <div class="placeholder-card large">
                    <div class="w-100">
                      <div class="avatar-placeholder blue" />
                      <div class="stripe-medium" />
                      <div class="stripe-small" />
                      <div class="stripe-large" />
                    </div>
                  </div>
                </el-col>
                <!-- /Placeholder 1 -->
                <!-- Placeholder 2 & Tutorial Tooltip -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" class="width-xl-20 mb-3">
                  <div class="placeholder-card large">

                    <!-- Placeholder 2 -->
                    <div class="w-100">
                      <div class="avatar-placeholder yellow" />
                      <div class="stripe-medium" />
                      <div class="stripe-small" />
                      <div class="stripe-large" />
                    </div>
                    <!-- /Placeholder 2 -->

                    <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
                    <tutorial-tooltip
                      v-if="$store.state.user.tutorial.productCompleted === false && 1 === 2"
                      :title="$t('pages.businessGuide.product.addYourProductOrService')"
                      :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                      :tutorial-name="'productCompleted'"
                    />
                    <!-- /Tutorial Tooltip -->

                  </div>
                </el-col>
                <!-- /Placeholder 2 & Tutorial Tooltip -->
              </div>

              <ib-draggable
                v-if="!isProductsEmpty"
                class="d-flex flex-wrap"
                :list="productsToShowGrid"
                :options="{disabled: hasNotAccess}"
                @change="onChangeProductOrder"
                @start="onStartProductOrder"
              >
                <template #header>
                  <el-col :md="8" class="d-flex width-xl-20 mb-3">
                    <add-new-card
                      class="width-xl-20"
                      :title="$t('pages.businessGuide.product.addProductOrService')"
                      :disabled="hasNotAccess"
                      @click="checkProduct"
                    />
                  </el-col>
                </template>
                <el-col
                  v-for="product in productsToShowGrid"
                  :key="product.id"
                  class="d-flex width-xl-20 draggable mb-3"
                  :md="8"
                >
                  <div
                    class="product-card cursor-move"
                    :class="{ 'no-diff': !product.differentiators }"
                    @click="checkCardProposition(product)"
                  >
                    <!-- Card Top -->
                    <div
                      class="card-top" :class="{ 'default': !product.image }"
                      :style="cardImageBySize(product.image, 'medium')"
                    >

                      <!-- Actions Dropdown -->
                      <actions-dropdown
                        v-if="canEdit && !statusOfCompleted"
                        ref="actionsDropdown"
                        :entity="product"
                        @edit="onEditProduct"
                        @copy="onCopyProduct"
                        @delete="onDeleteProduct"
                      />
                      <!-- /Actions Dropdown -->

                    </div>
                    <!-- /Card Top -->

                    <!-- Card Bottom -->
                    <div class="card-bottom">

                      <!-- Title -->
                      <h3>{{ product.name }}</h3>
                      <!-- /Title -->

                      <!-- Description -->
                      <p class="pre-formatted text-left ml-2 mr-2">
                        {{ product.description }}
                      </p>
                      <!-- /Description -->

                      <!-- Differentiators -->
                      <div class="category">
                        <p class="title">
                          {{ product.differentiators ? product.differentiators : 'No differentiators' }}
                        </p>
                      </div>
                      <!-- /Differentiators -->

                    </div>
                    <!-- /Card Bottom -->

                  </div>
                </el-col>
              </ib-draggable>

            </el-row>
            <!-- Show All -->
            <el-row v-if="$store.state.idea.storyMode.concept.product.products.length > 4">
              <el-col>
                <show-all-toggle
                  :show="showAllProductsGrid"
                  @click="showAllProductsGrid = !showAllProductsGrid"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /Grid Wrapper -->

          <!-- List Wrapper -->
          <div class="list-wrapper" :class="{ 'active': productView === 'list' }">
            <el-row :gutter="20">

              <!-- Completed overlay -->
              <completed-overlay
                v-if="hasNotAccess"
                @click="checkProduct"
              />
              <!-- /Completed overlay -->

              <ib-draggable
                :list="productsToShowList"
                :options="{disabled: hasNotAccess}"
                class="d-flex flex-wrap"
                @change="onChangeProductOrder"
                @start="onStartProductOrder"
              >
                <template #header>
                  <el-col :md="12" class="d-flex mb-3">
                    <add-new-card
                      class="width-xl-20"
                      :title="$t('pages.businessGuide.product.addProductOrService')"
                      :disabled="hasNotAccess"
                      horizontal
                      @click="checkProduct"
                    />
                  </el-col>
                </template>

                <!-- List Items -->
                <el-col
                  v-for="product in productsToShowList"
                  :key="product.id"
                  :md="12"
                  class="draggable mb-3"
                >
                  <div
                    class="product-card cursor-move"
                    :class="{ 'no-diff': !product.differentiators, 'completed-card': statusOfCompleted }"
                    @click="checkCardProposition(product)"
                  >
                    <!-- Card Top -->
                    <div class="card-top">

                      <!-- Card Image & Product Name -->
                      <div class="flex-inner">

                        <!-- Card Image -->
                        <div>
                          <div
                            class="card-image-round" :class="{ 'default': !product.image }"
                            :style="cardImageBySize(product.image, 'small')"
                          />
                        </div>
                        <!-- /Card Image -->

                        <!-- Product Name -->
                        <h3>{{ product.name }}</h3>
                      <!-- /Product Name -->

                      </div>
                      <!-- /Card Image & Product Name -->

                      <!-- Differentiators & Actions Dropdown -->
                      <div class="flex-inner">

                        <!-- Differentiators -->
                        <div class="category">
                          <p class="title">
                            {{ product.differentiators ? product.differentiators : 'No differentiators' }}
                          </p>
                        </div>
                        <!-- /Differentiators -->

                        <!-- Actions Dropdown -->
                        <actions-dropdown
                          v-if="canEdit && !statusOfCompleted"
                          ref="actionsDropdown"
                          :entity="product"
                          @edit="onEditProduct"
                          @copy="onCopyProduct"
                          @delete="onDeleteProduct"
                        />
                      <!-- /Actions Dropdown -->

                      </div>
                    <!-- /Differentiators & Actions Dropdown -->

                    </div>
                    <!-- /Card Top -->

                    <!-- Card Bottom -->
                    <div class="card-bottom">
                      <p class="pre-formatted">
                        {{ product.description }}
                      </p>
                    </div>
                  <!-- /Card Bottom -->

                  </div>
                </el-col>
              </ib-draggable>
              <!-- /List Items -->
            </el-row>
            <!-- Show All -->
            <el-row v-if="$store.state.idea.storyMode.concept.product.products.length > 5">
              <el-col>
                <show-all-toggle
                  :show="showAllProductsList"
                  @click="showAllProductsList = !showAllProductsList"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /List Wrapper -->

        </div>
        <!-- /Portfolio -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'concept'"
        :step="'product'"
        :concept-name="'productMoreDetails'"
        :completed-step-name="'productCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'productCompleted'"
      :step-filled="$store.state.idea.storyMode.concept.product.uniqueSellingPropositions.length > 0 && $store.state.idea.storyMode.concept.product.products.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="productExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add Product -->
    <dialog-add-product
      :dialog-add-product-visible="dialogAddProductVisible"
      :product-action="productAction"
      @close-dialog-add-product="onCloseDialogAddProduct"
    />
    <!-- /Dialog Add Product -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->
  </div>
  <!-- /Product -->

</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddProduct from './Dialogs/DialogAddProduct'
// import DialogAddProposition from './Dialogs/DialogAddProposition'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
// import UniqueSellingProposition from '@/views/Home/StoryMode/Concept/Product/UniqueSellingProposition/UniqueSellingProposition'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'

import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'Product',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    BusinessGuideSearch,
    ActionsDropdown,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddProduct,
    // DialogAddProposition,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    MainBottomBar,
    MainNavigation,
    ShowAllToggle,
    TutorialTooltip,
    // UniqueSellingProposition,
    ViewSwitcher
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      removeExamplesLoading: false,
      dialogRemoveExamplesVisible: false,
      dialogAddProductVisible: false,
      dialogAddPropositionVisible: false,
      dialogProcessCompletedVisible: false,
      guideVisible: false,
      productAction: null,
      showAllProductsGrid: false,
      showAllProductsList: false,
      updatingPropositions: false
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getProducts',
      'getUniqueSellingPropositions',
      'getStepIsCompleted',
      'getHasItemsFromTemplate'
    ]),

    products () {
      return this.getProducts
    },

    statusOfCompleted () {
      return this.getStepIsCompleted('productCompleted')
    },

    isProductsEmpty () {
      return !this.products.length
    },

    productsToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterProducts
      }

      return this.showAllProductsGrid === true
        ? this.products
        : this.products.slice(0, 4)
    },

    productsToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterProducts
      }

      return this.showAllProductsList === true
        ? this.products
        : this.products.slice(0, 5)
    },

    filterProducts () {
      return this.products.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    productView () {
      return this.$store.state.itemsView.productView
    },

    propositionItems () {
      return this.getUniqueSellingPropositions
    },

    hasNotAccess () {
      return !this.canEdit || this.statusOfCompleted
    },

    hasItemsFromTemplate () {
      return this.getHasItemsFromTemplate('getProducts') ||
        this.getHasItemsFromTemplate('getUniqueSellingPropositions')
    },

    removeButtonVisible () {
      return this.canEdit && this.hasItemsFromTemplate
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.idea'),
        route: 'business-guide-concept-idea'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.promotion'),
        route: 'business-guide-concept-promotion'
      }
    }
  },

  created () {
    this.openGuide('productGuide')
    this.getProduct()
  },

  methods: {
    ...mapActions('idea', [
      'getProduct',
      'deleteStoryModeProduct',
      'deleteIdeaExamples',
      'updateOrderNumber'
    ]),

    onStartProductOrder () {
      this.$refs.actionsDropdown.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeProductOrder (data) {
      this.updateOrderNumber({
        type: 'product',
        data,
        oldOrderData: this.getProducts,
        mutation: 'setProduct'
      })
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      Promise.all([
        this.deleteIdeaExamples('products'),
        this.deleteIdeaExamples('unique-selling-propositions')
      ]).then(response => {
        response.forEach(item => {
          const { type, ids } = item
          this.deleteFromState({ type, ids })
        })
        this.dialogRemoveExamplesVisible = false
        this.removeExamplesLoading = false
      }).catch(_ => { this.removeExamplesLoading = false })
    },

    checkAddProposition () {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true
      } else {
        this.dialogAddPropositionVisible = true
      }
    },

    checkCardProposition (product) {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true

        return
      }
      if (product.id) {
        this.onEditProduct(product)
      }
    },

    checkProduct () {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true
      } else {
        this.dialogAddProductVisible = true
      }
    },

    onCloseDialogAddProduct () {
      this.dialogAddProductVisible = false
      this.productAction = null
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setProductView', view)
    },

    onEditProduct (product) {
      this.productAction = Object.assign({}, product)
      this.dialogAddProductVisible = true
    },

    onCopyProduct (product) {
      this.productAction = Object.assign({}, product)
      this.productAction.id = 0
      this.dialogAddProductVisible = true
    },

    onDeleteProduct (product) {
      this.deleteStoryModeProduct(product.id)

      // Return to grid view, if there are no products
      if (this.$store.state.idea.storyMode.concept.product.products.length === 0) {
        this.onActivateView('grid')
      }
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    deleteProposition (index) {
      if (this.updatingPropositions) return
      this.$store.commit('idea/removeUniqueSellingPropositions', index)
      const propositions = this.$store.state.idea.storyMode.concept.product.uniqueSellingPropositions

      this.updatingPropositions = true
      this.$http.post(`story-mode/concept/product/unique-selling-proposition/${this.$store.state.idea.id}`, { uniqueSellingPropositions: propositions })
        .then((response) => {
          this.$store.commit('idea/setUniqueSellingPropositions', propositions)
        }).finally(() => {
          this.updatingPropositions = false
        })
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    }
  }
}
</script>

<style scoped>
  #story-mode-product {
    margin-left: 0;
  }
</style>
