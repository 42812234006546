<template>
  <!--  <ib-tooltip-->
  <!--    popper-class="small center"-->
  <!--    :content="$t('seeInstructionsForCompletingThisStep')"-->
  <!--  >-->

  <button
    class="info-button"
    :class="{'active': active}"
    :style="{'height': height}"
    @click="$emit('click')"
  >
    <div class="info-button-title d-flex align-items-center font-outfit-regular">
      <i class="ib-icon ib-icon-info-3" />
      <span :class="{'d-none d-md-flex': responsive}">Info</span>
    </div>
  </button>
<!--  </ib-tooltip>-->
</template>

<script>
export default {
  name: 'InfoButton',

  props: {
    height: {
      type: String,
      default: '38px'
    },
    responsive: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.info-button {
  background: $color-blue-button;
  border: 2px solid $color-blue-button;
  border-radius: 8px;
  transition: all 0.25s;

  &.active,
  &:hover {
    border: 2px solid $color-primary;
  }

  ::v-deep {
    padding: 0 10px;
  }

  .info-button-title {
    color: $color-primary;
    font-size: 17px;
  }

  .ib-icon {
    font-size: 26px;

    @include media-breakpoint-up($md) {
      padding: 0 5px 0 0;
    }
  }
}
</style>
